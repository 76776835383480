import  httpRequest  from './util/httpRequest';

var lock = true;
var lock1 = true;
var data = { }
var time = [57,58,59];
var new_number = [0];
var hr = [ 9,10,11,12,13,14,15,16,17,18,19,20]
var upr = [10,11,12,13,14,15,16,17,18,19,20,21]
var upr2 = [9,10,11,12,13,14,15,16,17,18,19,20,21]
var lck = true;
var myaudio = document.getElementById("audioID");
var audioChanged = false;


setInterval(function(){
  fetchLatestData();
},1000);

setInterval(function(){
  if(upr.includes(new Date().getHours()) && new Date().getMinutes() == 0 && new Date().getSeconds() > 0 && new Date().getSeconds() < 28){
    if(lck){
      lck = false;
      updateDashboard();
    }
  }
  else{
    lck = true;
  }
},1000);

setInterval(function(){
  if(hr.includes(new Date().getHours()) && time.includes(new Date().getMinutes()))
  { 
    if(lock1){
      var rotation = function (){
        $('.img-fluid').rotate({
          angle:0,
          animateTo:360,
          callback: rotation,
          easing: function (x,t,b,c,d){        // t: current time, b: begInnIng value, c: change In value, d: duration
            return c*(t/d)+b;
          }
        });
      }
      rotation();
      myaudio.src = window.serverData['spiningaudio'];
      audioChanged = true;
      lock1 = false;
    }
  }
  else
  {
    if(audioChanged){
      audioChanged = false;
      myaudio.src = window.serverData['audio'];
    }
    lock1 = true;
  }

},1000);

function fetchLatestData(){
  
  if(upr2.includes(new Date().getHours()) && new Date().getMinutes() == 59 && new Date().getSeconds() > 40 ){
    if(lock){
      lock = false;
      getdata();
    }
  }
  else{
    lock = true;
  }
}


function getdata(){
  httpRequest.get('/latest_data',{async: false})
    .done(function(res) { 
      if(Object.keys(res.data).length>0){
        data["ghanta"]  =  res.data['ghanta'];
        data["numbers"] =  res.data['numbers'];
        setupimages();
      }
      })
      .fail(function(error) {
        console.log("Server Failed");
      })
}

function updateDashboard(ghanta, numbers){

  if(Object.keys(data).length==0)
  {
    getdata();
  }
  setTimeout(function() {
    hideShow();
    updateData();
  },250);
  
}

function hideShow(){

  $('.img-fluid').stopRotate();
  $(".numbers_rotate").show();
  $(".static_image").hide();
  // $('.img-fluid').rotate({
  //   duration:40000,
  //   angle: 0,
  //   animateTo:7200
  // });
  var rotation = function (){
    $('.img-fluid').rotate({
      angle:0,
      animateTo:360,
      callback: rotation,
      easing: function (x,t,b,c,d){        // t: current time, b: begInnIng value, c: change In value, d: duration
        return c*(t/d)+b;
      }
    });
  }
  rotation();
  setTimeout(function() {
    $('.img-fluid').stopRotate();
    $(".numbers_rotate").hide();
    $(".static_image").show();
    var url = window.location.href;
    self.location = url;
  }, 30000);
}


function setupimages(){
  var string = data["numbers"];
  var first_digit = string[0];
  var second_digit = string[1];
  var third_digit = string[2];
  var p1 = window.serverData[first_digit]
  var p2 = window.serverData[second_digit]
  var p3 = window.serverData[third_digit]
  $("#spinner11").attr('src',p1);
  $("#spinner22").attr('src',p2);
  $("#spinner33").attr('src',p3);

}

function updateData(){

  if(Object.keys(data).length>0){
    let s = $('#ghanta')[0].innerText;
    if(s!=data["ghanta"]){
      var gh = document.createElement("div");
      gh.innerText = data["ghanta"];
      $("#gh").prepend(gh);

      var nm = document.createElement("div");
      nm.innerText = data["numbers"];
      $("#nm").prepend(nm);
    }
  }

}